
<script setup lang="ts">
    import { computed, onMounted, ref, watch } from "vue";
    import Matrix from "scopehazard.vue.components.matrix.Matrix.vue";
    import { getOrCreateDataObject } from 'o365-dataobject';
    import type { DataItemModel } from 'o365-dataobject';

    export interface IProps {
        dataObject: DataItemModel,
        properties: any
    }

    const props = defineProps<IProps>();

    const emit = defineEmits(['matrixUpdated']);

    const propertyNames = computed(() => props.properties?.map((property) => `'${property.name}'`));

    const consequenceBefore = computed(() => getNumberFromString(props.dataObject?.current.properties.ConsequenceBefore));
    const probabilityBefore = computed(() => getNumberFromString(props.dataObject?.current.properties.ProbabilityBefore));
    const consequenceAfter = computed(() => getNumberFromString(props.dataObject?.current.properties.ConsequenceAfter));
    const probabilityAfter = computed(() => getNumberFromString(props.dataObject?.current.properties.ProbabilityAfter));

    const A = computed(() => [probabilityAfter.value, consequenceAfter.value]);
    const B = computed(() => [probabilityBefore.value, consequenceBefore.value]);

    const dsPropertyValues = getOrCreateDataObject({
        id: 'o_propertyValuesLkp',
        viewName: 'stbl_System_PropertiesValues', 
        maxRecords: -1,
        whereClause: '',
        distinctRows: true,
        fields: [
            { name: "PropertyName", type: "string" },
            { name: "Value", type: "string" },
            { name: "SortOrder", type: "number"}
        ]
    });
    window.dsPropertyValues = dsPropertyValues;

    function getNumberFromString(str: String) {
        // Assumes first character in string is the number we want
        return parseInt(str?.[0]) - 1;
    }

    function setProperties(before: Array<number>, after: Array<number>) {
        const newBefore = before.map((num) => num + 1)
        const newAfter = after.map((num) => num + 1)

        let newPropertyValues: Array<Object> = [];

        if (!isNaN(newBefore[0]) && !isNaN(newBefore[1])) {
            newPropertyValues.push({
                property: 'ConsequenceBefore',
                newValue: getPropertyValues('ConsequenceBefore').find((value) => value.SortOrder == newBefore[1]).Value
            });
            newPropertyValues.push({
                property: 'ProbabilityBefore',
                newValue: getPropertyValues('ProbabilityBefore').find((value) => value.SortOrder == newBefore[0]).Value
            });
        }

        if (!isNaN(newAfter[0]) && !isNaN(newAfter[1])) {
            newPropertyValues.push({
                property: 'ConsequenceAfter',
                newValue: getPropertyValues('ConsequenceAfter').find((value) => value.SortOrder == newAfter[1]).Value
            });
            newPropertyValues.push({
                property: 'ProbabilityAfter',
                newValue: getPropertyValues('ProbabilityAfter').find((value) => value.SortOrder == newAfter[0]).Value
            });
        }

        // const beforeCoordinateObjects = [{
            // property: 'ConsequenceBefore',
            // newValue: getPropertyValues('ConsequenceBefore').find((value) => value.SortOrder == newBefore[1]).Value
        // }, {
            // property: 'ProbabilityBefore',
            // newValue: getPropertyValues('ProbabilityBefore').find((value) => value.SortOrder == newBefore[0]).Value
        // }];

        // const afterCoordinateObjects = [{
            // property: 'ConsequenceAfter',
            // newValue: getPropertyValues('ConsequenceAfter').find((value) => value.SortOrder == newAfter[1]).Value
        // }, {
            // property: 'ProbabilityAfter',
            // newValue: getPropertyValues('ProbabilityAfter').find((value) => value.SortOrder == newAfter[0]).Value
        // }];

        emit('matrixUpdated', newPropertyValues);
    }

    function getPropertyValues(property) {
        return dsPropertyValues.data.filter((propertyValues) => propertyValues.PropertyName == property);
    }

    watch(propertyNames, () => {
        dsPropertyValues.recordSource.whereClause = `PropertyName IN (${propertyNames.value.join(',')})`
        dsPropertyValues.load();
    });
</script>

<template>
    <Matrix
        :xAxis="$t('Consequence')"
        :yAxis="$t('Probability')"
        :a="A"
        :b="B"
        :bind="(after, before) => { setProperties(before, after) }"
        v-if="dsPropertyValues.state.isLoaded"
    />
</template>
